<template>
  <j-tile
    :badge-url="article.badge_src"
    :kicker="article.section ? article.section.name : null"
    :kicker-color="article.section ? article.section.highlight_color : null"
    :kicker-icon="article.featured ? 'star' : null"
    :headline="article.title"
    :headlineDate="article.releaseDate"
    :description="article.description"
    :tags="article.tags"
  >
    <!-- Actions -->
    <template #actions>
      <slot name="actions" />
    </template>

    <!-- Footer -->
    <template #footer>
      <slot name="footer" />
    </template>
  </j-tile>
</template>

<script>

export default {
  name: 'ArticleTile',
  // -------------
  // Properties ==
  // -------------
  props: {
    article: {
      type: Object,
      default: null
    }
  }
}
</script>
